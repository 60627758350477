import React, { useState, useEffect } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/theme/themeActions';
import HomeIcon from '@material-ui/icons/Home';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
import DehazeIcon from '@material-ui/icons/Dehaze';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArchiveIcon from '@material-ui/icons/Archive';

import AssignmentIcon from '@material-ui/icons/AssignmentTwoTone';
import Cached from '@material-ui/icons/Cached';

import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import TableViewTwoToneIcon from '@mui/icons-material/TableViewTwoTone';
import { fetchForms } from '../../reducers/list';
import _ from 'lodash';

const BLACKLIST_FORMS = [12, 13, 14];
// TODO: Fix this by introducing multi role architecture
const removeFastTrackForms = (forms) => {
  if (!forms) return [];
  const newForms = [];
  for (const form of forms) {
    if (!BLACKLIST_FORMS.includes(form.id)) {
      newForms.push(form);
    }
  }
  return newForms;
};

const IconComponent = (props) => {
  return (
    <>
      <props.menuItem.Icon />
    </>
  );
};

const SidebarMenuItem = (props) => {
  const { menuItem, index, setSidebarToggleMobile } = props;
  const [headerOpen, setHeaderOpen] = useState({
    dashboardOpen: false,
    projectOpen: false,
    programOpen: false,
    InitiativesOpen: false
  });
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const toggleHeader = (event, toggledItem) => {
    headerOpen[toggledItem]
      ? setHeaderOpen({ ...headerOpen, [toggledItem]: false })
      : setHeaderOpen({ ...headerOpen, [toggledItem]: true });

    event.preventDefault();
  };
  return (
    <li>
      {menuItem.children ? (
        <>
          <a
            onClick={(e) => toggleHeader(e, menuItem.toggledItem)}
            className={clsx({ active: headerOpen[menuItem.toggledItem] })}>
            <span className="sidebar-icon">
              <IconComponent menuItem={menuItem} />
            </span>
            <span className="mr-2">{menuItem.label}</span>
          </a>
          <Collapse in={headerOpen[menuItem.toggledItem]}>
            <ul>
              {menuItem.children.map((child, i) => (
                <li key={i}>
                  <NavLink onClick={toggleSidebarMobile} to={child.url}>
                    {child.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <NavLink
          activeClassName={`${
            index === 1
              ? 'active'
              : clsx({ active: headerOpen[menuItem.toggledItem] })
          }`}
          onClick={toggleSidebarMobile}
          className="nav-link-simple"
          to={menuItem?.url || '/'}>
          <span className="sidebar-icon">
            <IconComponent menuItem={menuItem} />
          </span>
          <span className="mr-2">{menuItem.label}</span>
        </NavLink>
      )}
    </li>
  );
};

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, getForms, forms, user } = props;

  const [menuItems, setMenuItems] = useState({});
  const [sidebarItems, setSidebarItems] = useState([]);
  useEffect(() => {
    if (user && Object.keys(user).length && !_.isEmpty(menuItems)) {
      let userAvailableSidebarItems = [];
      user.roles.forEach((role) => {
        menuItems[role.name].forEach((item) => {
          if (
            !userAvailableSidebarItems.some(
              (availableItems) => availableItems.label == item.label
            )
          ) {
            userAvailableSidebarItems.push(item);
          }
        });
      });
      setSidebarItems(userAvailableSidebarItems);
    }
  }, [menuItems]);
  useEffect(() => {
    if (user) {
      getForms({ model: 'Form' }).catch((err) => {});
    }
  }, [user]);

  useEffect(() => {
    if (forms?.length) {
      setMenuItems({
        requester: [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات',
            Icon: BallotTwoToneIcon,
            isHeader: true,
            toggledItem: 'dashboardOpen',
            children: [
              {
                label: 'طلب جديد',
                url: '/programs'
              },
              {
                label: 'الطلبات السابقه',
                url: '/worklist'
              }
            ]
          },

          {
            label: 'البرامج',
            Icon: LibraryBooksTwoToneIcon,
            isHeader: true,
            toggledItem: 'programOpen',
            children: removeFastTrackForms(forms).map((form) => {
              return {
                label: form.name,
                url: `/programs/${form.id}`
              };
            })
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        admin: [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات',
            Icon: BallotTwoToneIcon,
            isHeader: true,
            toggledItem: 'dashboardOpen',
            children: [
              {
                label: 'الطلبات السابقه',
                url: '/worklist'
              }
            ]
          },
          {
            label: 'لوحة المستخدمين',
            Icon: AccountCircle,
            url: '/users'
          }
        ],
        'super admin': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'لوحة التحكم',
            Icon: DehazeIcon,
            url: '/dashboard'
          },
          {
            label: 'الطلبات ',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'لوحة المستخدمين',
            Icon: AccountCircle,
            url: '/users'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],

        'administration office employee': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'administration office manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'research manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'general secertary': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'لوحة التحكم',
            Icon: DehazeIcon,
            url: '/dashboard'
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'excutive director': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'لوحة التحكم',
            Icon: DehazeIcon,
            url: '/dashboard'
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'research supervisor': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        researcher: [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],

        'finance manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'information office': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'research general manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات ',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        committee: [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات ',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'general secertary assistant medical': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'general secertary assistant social': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'medical division manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'medical division user': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'social division manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'social division user': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],

        'legal manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],

        employee: [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'financial manager': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        checker: [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'financial general secretary': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          },
          {
            label: 'الطلبات المؤرشفة',
            url: '/archivedSubmissions',
            Icon: ArchiveIcon
          }
        ],
        'web entry': [
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,

            url: '/worklist'
          }
        ],
        'architect': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          }
        ],
        'assistant secretary general for medical supply': [
          {
            label: 'الرئيسية',
            url: '/',
            Icon: HomeIcon
          },
          {
            label: 'الطلبات السابقه',
            Icon: BallotTwoToneIcon,
            url: '/worklist'
          }
        ]
      });
    }
  }, [forms]);

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation rtl">
          <ul className="pr-0">
            {sidebarItems.map((menuItem, i) => (
              <SidebarMenuItem
                key={i}
                menuItem={menuItem}
                index={i}
                setSidebarToggleMobile={setSidebarToggleMobile}
              />
            ))}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  forms: state.formList.forms,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  getForms: (params) => dispatch(fetchForms(params)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
